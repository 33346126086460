<template>
  <header>
    <div class="headertop">
    <div class="top_header justify-content-lg-between justify-content-center ">

      <h3>
        <span>summer savings</span> are here
      </h3>

      <div class="launchpromotion">
       <span>use code</span>
      

      <div class="input-group copycode">
        <input type="text"  class="form-control bg-transparent border-0 shadow-none p-0" placeholder="JULY35"  readonly v-model="couponCode" ref="couponCodeInput">
        <span class="input-group-text bg-transparent border-0 shadow-none p-0" @click="copyCouponCode()"> <img src="../assets/images/copy.svg"/></span>
      </div>
      <span>to get 35% discount !</span>
      </div>
    </div>
  </div>
    <div class="main_header">
      <div>
        <router-link to="/" class="outline-none border-none"
          ><img
            src="@/assets/images/logo.png"
            alt="logo"
            class="img-fluid logo"
        /></router-link>
      </div>

      <div class="navbar">
        <img
          src="../assets/images/close.png"
          alt="close"
          class="img-fluid d-md-none d-block sidebar_close"
          width="40px"
        />
        <nav>
          <ul>
            <li class="nav_list">
              <router-link to="/" class="nav_link"
                ><img
                  src="../assets/images/logo.png"
                  alt="logo"
                  class="img-fluid d-md-none d-block logo"
              /></router-link>
            </li>
            <li class="nav_list">
              <router-link to="/" class="nav_link">Home</router-link>
            </li>
            <li class="nav_list">
              <router-link to="/payout" class="nav_link">Payout</router-link>
            </li>
            <li class="nav_list">
              <router-link to="/product" class="nav_link">Products</router-link>
            </li>
            <li class="nav_list">
              <router-link to="/faq" class="nav_link">FAQs</router-link>
            </li>
            <li class="nav_list">
              <router-link to="contact" class="nav_link"
                >Contact Us</router-link
              >
            </li>
            <li>
              <a href="https://trading-dashboard.com/metrics/" class="nav_link">
                <button class="btn btn-primary">Login</button>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <img
        src="../assets/images/menu.png"
        alt="menu"
        class="img-fluid d-md-none d-block sidebar_open"
        width="40px"
      />
    </div>

    <div class="overlaysidebar d-md-none d-block"></div>
  </header>
</template>
<script>
export default {
  name: "HeaderComponent",
  data() {
    return {

       couponCode: 'JULY35'
    };
  },
  mounted() {
    let navlinks = document.querySelectorAll(".nav_link");

    navlinks.forEach((navlink) => {
      navlink.addEventListener("click", function (e) {
        navlinks.forEach((link) => link.classList.remove("active"));
        this.classList.add("active");
      });
    });

    let sidebaropen = document.querySelector(".sidebar_open");
    let overlaysidebar = document.querySelector(".overlaysidebar");
    let sidebarclose = document.querySelector(".sidebar_close");
    let navbar = document.querySelector(".navbar");
    sidebaropen.addEventListener("click", function () {
      navbar.classList.add("show");
      overlaysidebar.classList.add("show");
    });
    sidebarclose.addEventListener("click", function () {
      navbar.classList.remove("show");
      overlaysidebar.classList.remove("show");
    });

    overlaysidebar.addEventListener("click", function () {
      navbar.classList.remove("show");
      this.classList.remove("show");
    });
  },

  methods:{
    copyCouponCode() {

      this.$refs.couponCodeInput.select();
      
      try {
        // Execute copy command
        document.execCommand('copy');
        // Provide feedback or handle success as needed
        alert('Coupon code copied');
      } catch (err) {
        // Fallback or handle error
        console.error('Unable to copy coupon code:', err);
      }
    }
  }
};
</script>
<style scoped>


/* top_header css start */

.headertop {
  width:100%;
  background-color: var(--cool-blue);

}
.top_header {
  padding: var(--fs-10) var(--fs-20);
  max-width:1024px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: transparent;
  color:var(--white);
  gap:10px;
  text-align: center;
}
.top_header .launchpromotion {
  display:flex;
  align-items: center;
  justify-content: space-between;
  gap:10px;
}
.top_header .launchpromotion img{
 width:20px;

}
.top_header .launchpromotion span {
  font-weight: 700;
  font-size:15px;
  color:var(--cool-darkblue);
  text-transform: capitalize;
}

.top_header  span {
  color:var(--cool-darkblue)
}
.top_header  h3 {
  font-weight: 700;
  font-size:30px;
  color:var(--cool-white);
  margin-bottom: 0;
  line-height:36px;
  text-transform: uppercase;

}
.top_header .launchpromotion {
  font-weight: 700;
  font-size:15px;
  color:var(--white)
}
.top_header  .copycode {
  border:1px solid white;
  border-radius: 10px;
  padding:5px 10px;
  background: var(--cool-darkblue);
  color:var(--white) !important;
  width:100%;
  max-width: 100px;
}


.top_header  .copycode .form-control {
  color:var(--white);
  font-weight: 700;
  font-size:15px;
}
.top_header  .copycode .form-control::placeholder {
  color: var(--white);
  font-weight: 700;
  font-size:15px;
}
/* header css start */
header {
  background-color: var(--cool-darkblue);
}
.main_header {
  padding: var(--fs-8) var(--fs-20);
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}
.navbar {
  padding:0;
}
.navbar nav ul {
  display: flex;
  align-items: center;
  gap: var(--fs-30);

}


.navbar nav ul .nav_list .nav_link {
  color: var(--white);
  cursor: pointer;
  position: relative;
  font-size: var(--fs-16);
  font-weight: 600;
  transition: 0.1s;
  white-space:nowrap;
}

.navbar nav ul .nav_list .nav_link.active,
.navbar nav ul .nav_list .nav_link:hover {
  color: var(--cool-blue);
  filter: invert(44%) sepia(97%) saturate(2555%) hue-rotate(159deg) brightness(98%) contrast(100%);
}


.main_list {
  position:relative;
  width:100%;
}

.circle {
  width:7px;
}


.dropdown_menu {
  position: absolute;
  left:0;
  top:20px;
  width:150px;
  min-height: 200px;
  display:none;
  visibility: hidden;
  opacity: 0;
  z-index:999999;
  padding:10px;
  
}
.main_list:hover .dropdown_menu{
  display:block;
  visibility: visible;
  opacity: 1;

}
.dropdown_menu ul {
  display:flex;
  flex-direction: column;
  align-items: flex-start !important;
  background-color: var(--cool-black);
  border-radius:6px;
  box-shadow: var(--shadow3);
  border:1px solid var(--cool-gray);
  padding:var(--fs-20);
  width:100%;
}
.dropdown_menu ul .dropdown_list .dropdown_link {
  color:var(--cool-white);
  text-transform: capitalize;
  font-weight: 600;
  
}


.dropdown_menu ul .dropdown_list .dropdown_link:hover{ 

  color:var(--cool-blue);
  
}





@media  screen and (min-width:768px) and (max-width:991px){
  section {
    padding:100px 0;
  }

  
.main_header {
  flex-direction: column;
  gap: 10px;
}
}

@media screen and (min-width:576px) and (max-width:767px) {
  section {
    padding:70px 0;
  }

  .navbar {
      position:fixed;
      left:-100%;
      top:0;
      background-color: var(--cool-darkblue);
      height:100%;
      width:220px;
      z-index: 999;
      transition: 0.5s;
      
  }
  .navbar nav {
      width:100%;
      height:100%;
      padding:var(--fs-50) var(--fs-20)
  }
  
  .sidebar_close {
      position:absolute;
      right:6px;
      top:0;
  }
  .navbar nav ul {
      flex-direction: column;
      width:100%;
      align-items: flex-start;
      height:100%;
      
  }

  .overlaysidebar {
      position:fixed;
      top:0;
      width:100%;
      height:100%;
      background:rgba(0,0,0,0);
      z-index:998;
      left:-100%;
      transition: 0.5s;

  }
  .show {
      left:0%;
  }


  .dropdown_menu {
    position: static;
    width:100%;
    padding:0px;
    padding-top:var(--fs-10)
   
  }

  .dropdown_menu ul {
    background-color: transparent;
    border-radius:6px;
    box-shadow: var(--shadow3);
    border:1px solid transparent;
    padding:var(--fs-10);
    padding-bottom: 0;
    width:100%;
  }
  
}


@media screen and (min-width:320px) and (max-width:575px) {
    section {
      padding:70px 0;
    }
    /* video {
      display:none;
    } */
  
    .navbar {
        position:fixed;
        left:-100%;
        top:0;
        background-color: var(--cool-darkblue);
        height:100%;
        width:220px;
        z-index: 999;
        transition: 0.5s;
        
    }
    .navbar nav {
        width:100%;
        height:100%;
        padding:var(--fs-50) var(--fs-20)
    }
    
    .sidebar_close {
        position:absolute;
        right:6px;
        top:0;
    }
    .navbar nav ul {
        flex-direction: column;
        width:100%;
        align-items: flex-start;
        height:100%;
        padding-left: 0;
       margin-bottom: 0;
    }

    .overlaysidebar {
        position:fixed;
        top:0;
        width:100%;
        height:100%;
        background:rgba(0,0,0,0);
        z-index:998;
        left:-100%;
        transition: 0.5s;

    }
    .show {
        left:0%;
    }


    .dropdown_menu {
      position: static;
      width:100%;
      padding:0px;
      padding-top:var(--fs-10)
     
    }

    .dropdown_menu ul {
      background-color: transparent;
      border-radius:6px;
      box-shadow: var(--shadow3);
      border:1px solid transparent;
      padding:var(--fs-10);
      padding-bottom: 0;
      width:100%;
    }

    .top_header {
      padding:10px 0;
    }

    .top_header .launchpromotion span {
    font-size: 13px;
    white-space: nowrap;
}

}


</style>
