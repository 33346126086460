<template>
  <div>
    <HeaderComponent />
    <router-view />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>

<style>
@font-face {
  font-family: Teachers;
  src: url(../src/assets/fonts/Teachers-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Teachers;
  src: url(../src/assets/fonts/Teachers-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Teachers;
  src: url(../src/assets/fonts/Teachers-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Teachers;
  src: url(../src/assets/fonts/Teachers-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Teachers;
  src: url(../src/assets/fonts/Teachers-ExtraBold.ttf);
  font-weight: 800;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Teachers", sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  color: var(--white);
}

a {
  text-decoration: none !important;
  display: inline-block;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}



main {
  background-color: white;
}
:root {
  /*color variable */
  --cool-blue: #00a5c2;
  --cool-gray: #ffffff1a;
  --cool-lightgray: #918f8f;
  --cool-darkblue: #081344;
  --white: #ffffff;
  --black: #000000;
  --cool-black: #171717;

  /*gradient variable */
  --radialgradient: radial-gradient(at center, #fff, #09114b);
  --lineargradient: linear-gradient(
    135deg,
    rgba(0, 165, 194, 1) 77%,
    rgba(255, 255, 255, 1) 100%
  );

  /*box shadow variable */

  --shadow1: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  --shadow2: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  --shadow3: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  --shadow4: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  /*font size variable*/
  --fs-1: 0.063rem;
  --fs-2: 0.125rem;
  --fs-3: 0.188rem;
  --fs-4: 0.25rem;
  --fs-5: 0.313rem;
  --fs-6: 0.375rem;
  --fs-7: 0.438rem;
  --fs-8: 0.5rem;
  --fs-9: 0.563rem;
  --fs-10: 0.625rem;
  --fs-11: 0.688rem;
  --fs-12: 0.75rem;
  --fs-13: 0.813rem;
  --fs-14: 0.875rem;
  --fs-15: 0.938rem;
  --fs-16: 1rem;
  --fs-17: 1.063rem;
  --fs-18: 1.125rem;
  --fs-19: 1.188rem;
  --fs-20: 1.25rem;
  --fs-21: 1.313rem;
  --fs-22: 1.375rem;
  --fs-23: 1.438rem;
  --fs-24: 1.5rem;
  --fs-25: 1.563rem;
  --fs-26: 1.625rem;
  --fs-27: 1.688rem;
  --fs-28: 1.75rem;
  --fs-29: 1.813rem;
  --fs-30: 1.875rem;
  --fs-31: 1.938rem;
  --fs-32: 2rem;
  --fs-33: 2.063rem;
  --fs-34: 2.125rem;
  --fs-35: 2.188rem;
  --fs-36: 2.25rem;
  --fs-37: 2.313rem;
  --fs-38: 2.375rem;
  --fs-39: 2.438rem;
  --fs-40: 2.5rem;
  --fs-41: 2.563rem;
  --fs-42: 2.625rem;
  --fs-43: 2.688rem;
  --fs-44: 2.75rem;
  --fs-45: 2.813rem;
  --fs-46: 2.875rem;
  --fs-47: 2.938rem;
  --fs-48: 3rem;
  --fs-49: 3.063rem;
  --fs-50: 3.125rem;
}

.logo {
  width: 160px;
}

/*common css start */

.btn-primary {
  background: var(--cool-blue) !important;
  border: 2px solid var(--cool-blue) !important;
  color: var(--white) !important;
  border-radius: 50px !important;
  font-weight: 600 !important;
  padding: var(--fs-10) var(--fs-20) !important;
  box-shadow: none !important;
  transition: 0.5s !important;
}
.btn-primary:hover {
  background: var(--cool-blue) !important;
  border: 2px solid var(--cool-blue) !important;
  color: var(--white) !important;
  box-shadow: none !important;
}

.btn-border {
  background: transparent !important;
  border: 2px solid var(--cool-blue) !important;
  color: var(--white) !important;
  border-radius: 50px !important;
  font-weight: 600 !important;
  padding: var(--fs-10) var(--fs-20) !important;
  box-shadow: none !important;
  transition: 0.5s !important;
}
.btn-border:hover {
  background: var(--cool-blue) !important;
  border: 2px solid var(--cool-blue) !important;
  color: var(--white) !important;
  box-shadow: none !important;
}

section {
  padding: 120px 10px;
  position: relative;
  overflow: hidden;
}

.common_shape hr {
  width: 470px;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cool-blue);
  opacity: 1;
}

.main_heading h2 {
  font-size: var(--fs-40);
  font-weight: 800;
  color: var(--cool-darkblue);
  text-transform: capitalize;
  line-height: 52px;
  margin-bottom: var(--fs-10);
}

.main_heading p {
  font-size: var(--fs-16);
  font-weight: 600;
  color: var(--cool-lightgray);
  line-height: 28px;
  margin-bottom: var(--fs-30);
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--cool-gray);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--cool-blue);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--cool-gray);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--cool-gray);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* styles */
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  /* background-col 
  or: var(--cool-gray); */
}

.slider.round:before {
  border-radius: 50%;
}

.about_content .btn-join {
  border-color: var(--cool-darkblue) !important;
  color: var(--cool-darkblue) !important;
  background: transparent;
}
.about_content .btn-join:hover {
  color: var(--white) !important ;
  background: var(--cool-darkblue) !important;
}

.btn-currency {
  margin-top: 230px;
}

.btn-currency,
.btn-primary {
  width: 100% !important;
}
.experience_sec .experience_content .btn-experience {
  background-color: var(--cool-darkblue) !important;
  border-color: var(--cool-darkblue) !important;
  margin-top: var(--fs-40);
}

/* .currency_name h5 .badge-primary {
  background-color:var(--cool-blue) ;
  
} */

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .join_row div .join_data::after {
    right: -46%;
  }
  .banner_sec {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .program_sec .program_content h2 {
    font-size: var(--fs-28);
  }
  .join_row div .join_data::after {
    right: -46%;
    width: 237px;
  }
  .banner_sec {
    padding-bottom: 120px;
  }
  .bannerbg {
    height: 70%;
  }
  .challenge_sec .nav-link {
    padding: 14px;
  }
  .challenge_faq_sec .nav-link {
    padding: 14px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  section {
    padding: 100px 10px;
  }

  .program_sec .program_column {
    padding: 10px;
  }
  .join_row div .join_data::after {
    right: -46%;
    width: 160px;
  }
 

 
  .main_header {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  section {
    padding: 70px 10px;
  }


  .join_row div .join_data::after {
    right: -46%;
    width: 100px;
  }

  .banner_sec .banner_content h1 {
    font-size: 50px;
  }
  .banner_sec .banner_content img {
    width: 60px;
  }
  .program_sec .program_content h2 {
    font-size: var(--fs-28);
  }
  

  .challenge_sec .nav-tabs {
    background-color: transparent;
    gap: 50px;
    flex-direction: column;
  }
  .challenge_sec .nav-link {
    background-color: var(--cool-gray);
  }
  .challenge_sec .nav-link .rulres {
    transform: translate(-50%, -30px);
  }
  .challenge_sec .tab-pane nav .nav-tabs {
    gap: 10px;
  }

  .challenge_faq_sec .nav-tabs {
    background-color: transparent;
    gap: 50px;
    flex-direction: column;
  }
  .challenge_faq_sec .nav-link {
    background-color: var(--cool-gray);
  }
  .challenge_faq_sec .nav-link .rulres {
    transform: translate(-50%, -30px);
  }
  .challenge_faq_sec .tab-pane nav .nav-tabs {
    gap: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  section {
    padding: 60px 10px;
  }
  
}
 
</style>
