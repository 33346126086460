<template>
  <footer>
    <div class="container">
      <div class="row justify-content-between footer_main_list">
        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-4">
          <div class="footer_data">
          <div >
            <router-link to="/"
              ><img
                src="../assets/images/logo.png"
                alt="logo"
                class="img-fluid logo"
              />
            </router-link>
          </div>
          <div>
            <router-link to="/"
              ><img
                src="../assets/images/footerimg.jpg"
                alt="footerimg"
                class="img-fluid rounded-3 "
                width="400px"
              />
            </router-link>
          </div>

          <ul class="social_footer socilaicon">
            <!-- <li>
              <a href="">
                <img src="../assets/images/facebook.png" alt="facebook"
              /></a>
            </li>
            <li>
              <a href="">
                <img src="../assets/images/twitter.png" alt="twitter"
              /></a>
            </li> -->
            <li>
              <a href="https://www.instagram.com/myforexfunds.io?igsh=MTl2ZG02bTgwYnFzeQ%3D%3D&utm_source=qr">
                <img src="../assets/images/instagram.png" alt="instagram"
              /></a>
            </li>
            <li>
              <a href="https://www.youtube.com/@myforexfunds2024">
                <img src="../assets/images/youtube.png" alt="youtube"
              /></a>
            </li>
          </ul>
        </div>
        </div>

        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-3 col-6">
          <div class="footer_data">
          <h4>Navigation</h4>
          <ul>
            <li class="footer_list">
                <router-link
                  to="/"
                  class="footer_link"
                  >Home</router-link
                >
              </li>
              
              <li class="footer_list">
                <router-link to="/payout" class="footer_link"
                  >Payout</router-link
                >
              </li>
              <li class="footer_list">
                <router-link to="/product" class="footer_link"
                  >Product</router-link
                >
              </li>
             
            </ul>
          </div>
        </div>

        <div class="col-xl-2 col-lg-2 col-md-5 col-sm-3 col-6">
          <div class="footer_data">
          <h4>
            Support
          </h4>

          <ul>
             
             
              <li class="footer_list">
                <router-link to="/faq" class="footer_link"
                  >Faq</router-link
                >
              </li>
              <li class="footer_list">
                <router-link to="/contact" class="footer_link"
                  >Contact</router-link
                >
              </li>

              <li class="footer_list">
                <router-link to="/contact" class="footer_link"
                  >Be a Partner</router-link
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-7 ">
          <div class="footer_data">
          <h4>Legal Policy</h4>

          <ul class="social_footer">
              <li class="policies">
                <span @click="download(`Privacy-Policy.pdf`)"
                  >Privacy Policy</span
                >
              </li>
              <li class="policies">
                <span @click="download(`Term-Of-Service.pdf`)"
                  >Terms of Services</span
                >
              </li>
              <li class="policies">
                <span @click="download(`COOKIE-POLICY.pdf`)"
                  >Cookie Policy</span
                >
              </li>
              <li class="policies">
                <span @click="download(`RefundandCancellationPolicy.pdf`)"
                  >Refund and Cancellation Policy</span
                >
              </li>
              <!--  -->
            </ul>

            <ul class="social_footer socilaicon paymentmethod">
            <li>
             
                <img src="../assets/images/mastercard.png" alt="mastercard"
              />
            </li>
            <li>
              
                <img src="../assets/images/visa.png" alt="visa"
              />
            </li>
            <li>
              
                <img src="../assets/images/express.png" alt="express"
              />
            </li>
            <li>
             
                <img src="../assets/images/discover.png" alt="discover"
              />
            </li>
          </ul>
        </div>
        </div>

        <div class="col-12">
          <div class=" footer_content">
            <p>
              All content published and distributed by MFF WEALTH GROUP LTD and its affiliates is intended
              to be treated as general information only. None of the information provided by the company or
              contained here is intended as investment advice, an offer or solicitation of an offer to buy or sell,
              or as a recommendation, endorsement, or sponsorship of any security, company, or fund.
              Testimonials may not be representative of other clients or customers and are not a guarantee of
              future performance or success. MyForexFunds is not a broker and does not accept client
              deposits. All program fees are used for operational costs, including staff, technology, and other
              business-related expenses. MyForexFunds itself does not carry out any regulated activities; our
              exclusive activities are Prop Trading and Trading Education and we are therefore not required to
              be authorized by regulatory authorities.
          </p>
          <p>
            MFF WEALTH GROUP LTD serves as an Introducing Broker, offering clients access exclusively
            to demo accounts within a simulated trading environment. Additionally, the services offered are
            aimed at assessing professional skills. The results of these services are directly related to each
            individual's professional skill level and their capacity to follow the program guidelines and
            objectives detailed for each service. We encourage clients to conduct a comprehensive review
            of the program requirements before enrolling in any services. Disclosure on Hypothetical
            Performance: The accounts utilized for our services are demo accounts. Hypothetical
            performance results come with inherent limitations, among which include the fact that they do
            not reflect actual trading. No claim is made that any account will achieve or is likely to achieve
            profits or losses comparable to those discussed; in reality, actual results can significantly differ
            from those predicted by hypothetical performance. Hypothetical performance often benefits from
            hindsight, does not account for financial risk, and cannot consider the financial risk in actual
            trading. For instance, the capacity to endure losses or to stick to a trading plan despite losses
            are crucial factors that can negatively impact real trading results. Many other market factors or
            aspects of implementing a specific trading program not accounted for in hypothetical
            performance preparation can also adversely affect actual trading results. Please be aware that
            we do not offer specific investment advice, business consulting, analysis of investment
            opportunities, or any general recommendations on trading investment instruments. Trading in
            financial markets carries a high level of risk, and we advise against risking more than you can
            afford to lose.
          </p>
          <p>
            MyForexFunds funded accounts are not live trading accounts, they are fully simulated accounts
            utilizing real market quotes from liquidity providers.
          </p>
          <p class="copyright">
            MFF WEALTH GROUP LTD, Company Number 15830725 ,5 Brayford Square London E1 0SG
          </p>
          <p class="copyright">
            <img
              src="../assets/images/copyright.png"
              alt="copyright"
              width="20px"
            />
            <span>My Forex Funds 2024</span>
          </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import ApiClass from '@/api/api.js';

export default {
  name: "FooterComponent",
  data(){
    return {
      pdfurl : ApiClass.pdfUrl
    }
  },
  methods:{

    download(url) { 
      url = this.pdfurl + url;
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank';
      a.download = url.split('/').pop() 
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
};
</script>
<style scoped>

/* footer css start */
footer {
  background-color: var(--black);
  width:100%;
  display:block;
  padding:var(--fs-50) 0;
}

.footer_data {
  margin-bottom:40px !important;
}
footer .social_footer {
  display:flex;
  align-items: center;
  gap:20px;
  margin-top:20px;
  padding:0px;
}

footer .social_footer li a {
  border:2px solid var(--white);
  border-radius: 50%;
  min-width:40px;
  height:40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .social_footer li a img {
  min-width:20px;
  height:20px;
}
footer .social_footer li a:hover {
  border: 2px solid var(--cool-blue);
  
}
footer .social_footer li a:hover img {
  filter: brightness(0) saturate(100%) invert(43%) sepia(88%) saturate(726%) hue-rotate(148deg) brightness(94%) contrast(103%);
}
footer p {
  font-size:var(--fs-16);
  font-weight:600;
  color:var(--cool-lightgray);
  line-height:28px;
  margin-top:25px;
}
footer .copyright {
  display: flex;
  align-items: center;
  gap:8px;
}
footer .copyright img{
 width:15px;
}

footer .copyright span {
  font-size:var(--fs-14);
  font-weight:600;
  color:var(--white);
  
}

footer .footer_main_list .social_footer {
   flex-direction: column;
   align-items: flex-start;
   padding:0px;
  
   
}

footer .socilaicon {
  flex-direction: row !important;
}
footer .footer_main_list h4 {
  color:var(--cool-blue);
  font-size:20px;
  font-weight: 600;
  margin-bottom: 20px;
}
footer .footer_main_list ul .footer_link{
  font-size:var(--fs-15);
  font-weight:600;
  color:var(--white);
  margin-bottom:20px;
  

}
footer .footer_main_list ul {
  padding:0px;
}

footer .footer_main_list ul .footer_link:hover{
  color:var(--cool-blue);
  
}
footer .footer_main_list .privacy  {
  margin-top:var(--fs-20)
}
footer .footer_main_list .privacy li a  {
  font-size:var(--fs-15);
  font-weight:500;
  color:var(--white) ;
  margin-bottom:5px;;
  text-decoration: none;
}

.policies{
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: var(--cool-blue);
  font-size: 15px;
  font-weight:600;
  cursor: pointer;
  color:var(--white);
}
.policies:hover{
  color:var(--cool-blue)
}




.paymentmethod li  img{
  width:60px;
  align-items: center;
}

.footer_content {
  border-top:1px solid rgba(255,255,255,0.3)
}

.footer_content .copyright {
  color:var(--white);
}
</style>